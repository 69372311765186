.header-desktop__menu {
  align-self: center;
}

.header-desktop__menu-item {
  font-size: 1.1em;
  transition: all 0.5s ease;
  margin: 0 var(--margin-s);
  cursor: pointer;
  border-bottom: 2px solid transparent;
  outline: none;
  color: var(--color-font)
}

.header-desktop__menu-item:hover {
  color: var(--color-primary);
  transition: all 0.5s ease;
}

.header-desktop__menu-item.active {
  border-bottom-color: var(--color-primary);
  transition: all 0.5s ease;
}

.header-desktop__menu-separator:after {
  font-size: 1.1em;
  color: var(--color-font);
  content: '|';
}

.header-desktop a {
  display: flex;
  flex-flow: column;
  justify-content: center;
  outline: none;
}
