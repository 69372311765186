@media screen and (max-width: 789px) {
    .home__col {
        width: 100%;
    }

    .home__switchable-row {
        flex-flow: column;
    }
}

@media screen and (min-width: 790px) {
    .home__col {
        width: 50%;
    }
}
