.photo {
    height: 200px;
    width: 200px;
    align-self: center;
    margin: 1em;
    background: transparent url('../images/face.png') no-repeat center;
    background-size: cover;
    border-radius: 100px;
}

.git {
    background: transparent url('../images/logo/git.png') no-repeat center;
    background-size: cover;
}

.redux {
    background: transparent url('../images/logo/redux.png') no-repeat center;
    background-size: cover;
}

.gitlab {
    background: transparent url('../images/logo/gitlab.png') no-repeat center;
    background-size: cover;
}

.jest {
    background: transparent url('../images/logo/jest.png') no-repeat center;
    background-size: cover;
}

.angularjs {
    background: transparent url('../images/logo/angularjs.png') no-repeat center;
    background-size: cover;
}

.angular {
    background: transparent url('../images/logo/angular.png') no-repeat center;
    background-size: cover;
}

.react {
    background: transparent url('../images/logo/react.png') no-repeat center;
    background-size: cover;
}

.koa {
    background: transparent url('../images/logo/koajs.png') no-repeat center;
    background-size: cover;
}

.express {
    background: transparent url('../images/logo/express.jpg') no-repeat center;
    background-size: cover;
}

.hapi {
    background: transparent url('../images/logo/hapi.png') no-repeat center;
    background-size: cover;
}

.sequelize {
    background: transparent url('../images/logo/sequelize.png') no-repeat center;
    background-size: cover;
}

.mongoose {
    background: transparent url('../images/logo/mongoose.png') no-repeat center;
    background-size: cover;
}

.html {
    background: transparent url('../images/logo/html5.png') no-repeat center;
    background-size: cover;
}

.css {
    background: transparent url('../images/logo/css3.png') no-repeat center;
    background-size: cover;
}

.js {
    background: transparent url('../images/logo/js.png') no-repeat center;
    background-size: cover;
}

.ts {
    background: transparent url('../images/logo/ts.png') no-repeat center;
    background-size: cover;
}

.shell {
    background: transparent url('../images/logo/shell.png') no-repeat center;
    background-size: cover;
}

.mysql {
    background: transparent url('../images/logo/mysql.png') no-repeat center;
    background-size: cover;
}

.pgsql {
    background: transparent url('../images/logo/pgsql.png') no-repeat center;
    background-size: cover;
}

.mongodb {
    background: transparent url('../images/logo/mongodb.png') no-repeat center;
    background-size: cover;
}

.firebase {
    background: transparent url('../images/logo/firebase.png') no-repeat center;
    background-size: cover;
}

.docker {
    background: transparent url('../images/logo/docker.png') no-repeat center;
    background-size: cover;
}

.jenkins {
    background: transparent url('../images/logo/jenkins.png') no-repeat center;
    background-size: cover;
}

.fr {
    background: transparent url('../images/logo/fr.png') no-repeat center;
    background-size: cover;
}

.gb {
    background: transparent url('../images/logo/gb.png') no-repeat center;
    background-size: cover;
}

.github {
    background: transparent url('../images/logo/github.png') no-repeat center;
    background-size: cover;
}

.node {
    background: transparent url('../images/logo/nodejs.png') no-repeat center;
    background-size: cover;
}

.arduino {
    background: transparent url('../images/logo/arduino.png') no-repeat center;
    background-size: cover;
}

.less {
    background: transparent url('../images/logo/less.png') no-repeat center;
    background-size: cover;
}

.vue {
    background: transparent url('../images/logo/vue.png') no-repeat center;
    background-size: cover;
}

.react-native {
    background: transparent url('../images/logo/react-native.png') no-repeat center;
    background-size: cover;
}

.rethinkdb {
    background: transparent url('../images/logo/rethinkdb.png') no-repeat center;
    background-size: cover;
}

.elasticsearch {
    background: transparent url('../images/logo/elasticsearch.png') no-repeat center;
    background-size: cover;
}
