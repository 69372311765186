.mission__container {
  padding: 0 var(--margin-m);
}

.mission__line {
  height: 2px;
  width: 95%;
  background: var(--color-primary);
  margin: var(--margin-s) auto;
}
