.level-indicator__point {
  height: 16px;
  width: 16px;
  display: inline-flex;
  margin: 0 1px;
  border: 1px solid var(--color-level-0);
  border-radius: 10px;
  cursor: default;
  transition: all 1s ease;
}

.level-indicator__sub-point {
  width: 8px;
  height: 8px;
  background: var(--color-level-0);
  align-self: center;
  margin: auto;
  border-radius: 5px;
  transition: all 1s ease;
}

.level-indicator__point__level-1__active {
  border-color: var(--color-level-1);
  transition: all 1s ease;
}

.level-indicator__point__level-1__active > .level-indicator__sub-point {
  background: var(--color-level-1);
  transition: all 1.5s ease;
}

.level-indicator__point__level-2__active {
  border-color: var(--color-level-2);
  transition: all 2s ease;
}

.level-indicator__point__level-2__active > .level-indicator__sub-point {
  background: var(--color-level-2);
  transition: all 2.5s ease;
}

.level-indicator__point__level-3__active {
  border-color: var(--color-level-3);
  transition: all 3s ease;
}

.level-indicator__point__level-3__active > .level-indicator__sub-point {
  background: var(--color-level-3);
  transition: all 3.5s ease;
}

.level-indicator__point__level-4__active {
  border-color: var(--color-level-4);
  transition: all 4s ease;
}

.level-indicator__point__level-4__active > .level-indicator__sub-point {
  background: var(--color-level-4);
  transition: all 4.5s ease;
}

.level-indicator__point__level-5__active {
  border-color: var(--color-level-5);
  transition: all 5s ease;
}

.level-indicator__point__level-5__active > .level-indicator__sub-point {
  background: var(--color-level-5);
  transition: all 5.5s ease;
}
