.row.header-mobile__head {
  height: 45px;
}

.row.header-mobile__menu {
  transition: all 1s ease;
  overflow: hidden;
  display: flex;
  border-bottom: 1px solid transparent;
}

.header-mobile__closed {
  height: 0 !important;
  transition: all 0.5s ease;
}

.header-mobile__opened {
  height: 215px !important;
  transition: all 0.5s ease;
  border-bottom: 1px solid var(--color-primary-light) !important;
}

.header-mobile__menu-toggle-container {
  transition: all 0.5s ease;
  min-width: 100px;
  font-size: 1.25em;
}

.header-mobile__menu-toggle-container:hover {
  transition: all 0.5s ease;
  color: var(--color-primary);
}

.header-mobile__menu-icon {
  width: 20px;
}

.header-mobile__menu-item {
  font-size: 1.1em;
  transition: all 0.5s ease;
  margin: 0 var(--margin-s);
  border-left: 2px solid transparent;
  outline: none;
}

.header-mobile__menu-item:hover {
  color: var(--color-primary);
  transition: all 0.5s ease;
  background: var(--color-primary-ultra-light);
}

.header-mobile__menu-item-active {
  border-left-color: var(--color-primary);
  transition: all 0.5s ease;
}
