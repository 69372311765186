.tech-item__col {
  width: max-content;
}

.tech-item__round {
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background: white;
  position: relative;
  top: 8px;
  left: -8px;
}

.tech-item__technology {
  background: lightgray;
  height: 20px;
  display: inline-flex;
  flex-flow: row;
  border-radius: 11px 3px 11px 3px;
}
