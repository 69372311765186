.skill-legend__container {
  margin: auto;
  padding: 0 var(--margin-m) var(--margin-m);
}

.skill-legend__level-container {
  min-width: 120px;
}

.skill-legend__text-value {
  padding: var(--margin-s) var(--margin-m);
  transition: all 0.5s ease;
}
