.skill-item__icon {
  margin: 2px;
  width: 36px;
  height: 36px;
}

.skill-item__logo-container {
  width: 40px;
}

.skill-item__element-wrapper {
  padding: var(--margin-xs);
}

.skill-item__level-container {
  width: 110px;
}

.skill-item__text-container {
  width: 100px;
}
