.info-block {
    transition: all 0.5s ease;
    padding: var(--margin-s);
    margin: var(--margin-xs);
    border: 1px solid transparent;
    border-radius: 3px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    cursor: default;
}

.info-block_hoverable {
    cursor: pointer;
}

.info-block_hoverable:hover {
    transition: all 0.5s ease;
    border-color: #540ead;
    background: rgba(8, 111, 161, 0.05);
}

.info-block_hoverable:hover .info-block__arrow {
    opacity: 1;
    transform: scale(1);
    transition: all 1s ease;
    margin-right: 0;
}

.info-block__arrow {
    opacity: 0;
    transform: scale(0);
    margin-right: -20px;
    transition: all 0.75s ease;
    height: 25px;
    background: rgba(84, 14, 173, 0.8);
    width: 25px;
    align-self: center;
    border-radius: 25px;
    color: white;
}

.info-block__arrow > .fas {
    font-size: 19px;
    margin-top: 2px;
}
