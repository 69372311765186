.sub-title {
  font-weight: bold;
  justify-content: center;
  font-size: 1.3em;
  margin-top: 0.2em;
}

.sub-title.link > i {
  font-size: 0.8em;
}

.location {
  color: #555;
}

.date-text {
  margin-top: 0.4em;
  font-style: italic;
  font-size: 0.9em;
}

.color-primary {
  color: var(--color-primary);
}
