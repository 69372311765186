#pdf {
  font-size: 1.1em;
}

.cv__part {
  border-bottom: 1px solid var(--color-primary);
}

@media (max-width: 520px) {
  #pdf .row {
    flex-flow: column;
  }

  #pdf .mobile-reverse .row {
    flex-flow: column-reverse;
  }

  #pdf .col {
    width: 100%;
  }
}

.cv__download {
  background: var(--color-primary-ultra-light);
  border: 1px solid var(--color-primary-lighter);
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.5s ease;
  outline: none;
}

.cv__download:hover {
  transition: all 0.5s ease;
  background: rgba(84, 14, 173, 0.1);
  border-color: var(--color-primary);
}

.cv__button-text {
  line-height: 1.4em;
}

.cv__date-container {
  min-width: fit-content;
}
