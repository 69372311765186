.clickable:hover > .collapse-panel__line, .clickable.active > .collapse-panel__line {
    width: 100%;
    transition: all 2s ease;
    background: var(--color-primary);
}

.clickable .collapse-panel__toggle {
    transition: all 0.5s ease;
}

.clickable:hover .collapse-panel__toggle {
    transition: all 0.5s ease;
    color: var(--color-primary);
}

.collapse-panel__line {
    transition: all 1s ease;
    width: 1px;
    height: 4px;
    background: transparent;
}

.collapse-panel__details-bloc {
    overflow: hidden;
    transition: all 1.5s ease;
}

.collapse-panel__toggle {
    font-size: 1em;
}
