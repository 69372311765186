.cv-personal__phone-number {
  font-size: 0.8em;
}

.cv-personal__link {
  color: var(--color-font);
}

.cv-personal__name {
  color: #086fa1;
}

.cv-personal__position {
  color: #666;
}
