.skills__search-input {
  width: 65%;
  margin: var(--margin-m) auto;
  border-radius: 3px;
  transition: all 0.5s ease;
  font-size: 1em;
  outline: none;
  background: #fafafa;
  padding: var(--margin-xs);
  border: 1px solid var(--color-primary-lightest);
}

.skills__search-input:hover {
  border: 1px solid var(--color-primary-lighter);
  transition: all 0.5s ease;
}

.skills__search-input:focus {
  border: 1px solid var(--color-primary-light);
  transition: all 0.5s ease;
}
