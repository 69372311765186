@import url('https://fonts.googleapis.com/css?family=Jura:400,700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.8.2/css/all.css');
@import "styles/constants.css";
@import "styles/margin.css";
@import "styles/images.css";
@import "styles/texts.css";

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Jura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-font);
  background: var(--color-background);
  outline: none;
}

header {
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--color-font);
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.row {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.col {
  display: flex;
  flex-flow: column;
}

.wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between !important;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

.bold {
  font-weight: bold;
}

.full {
  width: 100%;
}

.link {
  cursor: pointer;
  transition: all 0.5s ease;
  text-decoration: none;
  color: var(--color-font);
}

.link:hover {
  color: var(--color-primary);
  transition: all 0.5s ease;
}

.float-right {
  float: right;
}
