.header {
  min-height: 45px;
  overflow: hidden;
}

.header__shadowed {
  z-index: 10;
  transition: all 0.5s ease;
  -moz-box-shadow: 0 1px 0 0 rgba(32, 33, 36, 0);
  -webkit-box-shadow: 0 1px 0 0 rgba(32, 33, 36, 0);
  -o-box-shadow: 0 1px 0 0 rgba(32, 33, 36, 0);
  box-shadow: 0 1px 0 0 rgba(32, 33, 36, 0);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#FFFFFF, Direction=180, Strength=0);
}

.header__shadowed-overflowing {
  transition: all 0.5s ease;
  -moz-box-shadow: 0 1px 8px 0 rgba(32, 33, 36, 1);
  -webkit-box-shadow: 0 1px 8px 0 rgba(32, 33, 36, 1);
  -o-box-shadow: 0 1px 8px 0 rgba(32, 33, 36, 1);
  box-shadow: 0 1px 8px 0 rgba(32, 33, 36, 1);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#1F2124, Direction=180, Strength=8);
}

.header__mobile {
  display: none;
}

@media (max-width: 620px) {
  .header__desktop {
    display: none !important;
  }

  .header__mobile {
    display: flex;
  }
}
